.footer {
    background-color: #4895ef;
    padding: 5px;
    color: white;
    display: flex;
    margin-top: 40px;
    text-align: center;
}
.footer p{
    margin-left: 20px;
    font-size: smaller;
}