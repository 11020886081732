.teamSection1{
    text-align: center;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }
  .memberArea{
    border: none;
    padding: 20px;
    margin-top: 1px;
    margin-bottom: 1px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
  }
  .memberImageSize{
    overflow: hidden;
    width: 300px;
    height: 300px;
    border: solid 1px #040228;
    border-radius: 5px;
  }
  
  
  @media only screen and (max-width:700px){
    .teamSection1{
        display: block;
    }
  }
  