.bg {
    width: 100%;
    display: block;
}

.bg-div {
    position: relative;
}

.bg-div h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);
    font-size: 100px;
    font-weight: bold;
}

p {
    font-size: large;
}
.primary-section {
    border: solid 1px #000;
    border-radius: 5px;
    max-width: 300px;
    margin: auto;
    background-color: #fb8500;
    color: #fff;
}

.section1 {
    text-align: center;
    margin-bottom: 100px;
  }
  .section1 h1{
    font-size: 70px;
  }

.section2{
    display: flex;
    justify-content: space-between;
}
.section2Info {
    position: relative;
    max-width: 375px;
    height: 250px;
    border: none;
    border-radius: 5px;
    overflow: hidden;
}
.section2Info h2 {
    color: #000;
    position: absolute;
    top: 40%;
    left: 30%;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
}
.section2Info h3 {
    color: #000;
    position: absolute;
    top: 10%;
    left: 30%;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
}
.section2Info ul {
    color: #000;
    position: absolute;
    top: 40%;
    left: 30%;
    background-color: white;
    border-radius: 5px;
    padding: 5px;
}
@media only screen and (max-width:700px){
    .section1 h1 {
        font-size: 30px;
    }
}


@media only screen and (max-width:1200px){
    .section2 {
        display: block;
        
    }
    .section2Info {
        margin: auto;
        margin-bottom: 10px;
    }
    .bg-div{
        overflow: hidden;
    }
    .bg-div img{
        display: flex;
        height: 400px;
        width: auto;
      }
  }

