.navbar{
    display: flex;
    width: 100%;
}

.logo {
    width: 30px;
    margin-right: 20px;
    cursor: pointer;
    margin-left: 15px;
    position: absolute;
    margin-top: 10px;
}
.menu-icon{
    display: none;
    cursor: pointer;
    width: 0px;
    margin-right: 0px;
    position: absolute;  
    margin-top: 10px;
    right: 5px;
}
nav{
    flex: 1;
    text-align: right;
    margin-left: 10px;
    margin-right: 10px;
}
nav ul li{
    cursor: pointer;
    display: inline-block;
    margin-right: 35px;
    border-bottom: solid 1px ;
    border-image: linear-gradient(to right, #fb8500, #3a0ca3, #4cc9f0) 1;
    border-image-width: 0px;
}
nav ul :hover{
    border-image-width: 1;
    transition: 1s;
}

body.dark-mode :link, :visited{
    color: #fb8500;
    text-decoration: none;
   
}
body.light-mode :link {
    color: #000;
    text-decoration: none;
}

@media only screen and (max-width:700px){

    nav ul li{
        display: block;
        margin-top: 7px;
        margin-right: 50px;
    }
    .menu-icon{
        display: block;
        width: 30px;
        margin-right: 15px;  
    }
}